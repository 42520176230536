import styled, { css } from 'styled-components';

const anchorStyles = css`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text.primary};
  font-size: 14px;
  line-height: 24px;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.text.accent};
  }
`;

const secondaryText = css`
  font-family: var(--font-futura);
  font-weight: 300;
  font-size: 12px;
  color: ${({ theme }) => theme.text.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0;
  }
`;

const StyledFooter = {
  FooterWrapper: styled.footer`
    border-top: 1px solid ${({ theme }) => theme.border.disabled};
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: ${({ theme }) => theme.background.primary};
    width: ${({ theme }) => theme.pineLayoutMaxWidth};
    margin: 0 auto;
    padding: 20px 0;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      flex-direction: column;
      width: 100%;
    }
  `,
  AnchorWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  `,
  Anchor: styled.a`
    ${anchorStyles}
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 14px;
  `,
  SecondaryAnchor: styled.a`
    ${anchorStyles}
    ${secondaryText}
      &:focus,
      &:hover {
      text-decoration: underline;
    }
  `,
  TermsConditionsWrapper: styled.div`
    display: flex;
    text-align: center;
    gap: 20px;
    justify-content: flex-end;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      justify-content: center;
      column-gap: 32px;
      flex-wrap: wrap;
      padding: 0 20px;
    }
  `,
  Text: styled.p`
    ${secondaryText}
  `,
  TextFsra: styled.p`
    ${secondaryText}
    font-size: 10px;
    line-height: 24px;
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 8px;
      line-height: 12px;
    }
  `,
};

export default StyledFooter;
