import { CUSTOMER_PORTAL_ROUTES, ROUTES } from '@models/enums';
import { LoanStage, MortgageApplicationClientDto, MortgageApplicationDto } from '@pinecorpca/evergreen';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { getLastCompletedSection } from 'utils/navigate-flow.utils';

interface NavigateFlowProps {
  application: MortgageApplicationClientDto | undefined;
  children: React.ReactNode;
}

const NavigateFlow: FunctionComponent<NavigateFlowProps> = ({ application, children }) => {
  const mountRef = useRef(false);
  const router = useRouter();
  const [isRouting, setRouting] = useState(false);

  const completedSection = useMemo(() => getLastCompletedSection(application as MortgageApplicationDto), [application]);

  useEffect(() => {
    // beforePopState is used to check the route before a navigation event occurs
    // in the rare case a user attempts to use browser back/forward buttons to navigate, this ensures they are not redirected to an incorrect step
    router.beforePopState(({ as }) => {
      const routerPath = as?.includes('#') ? as.split('#')[0] : as;
      const isCompleted = completedSection.sections?.find((section) => section.path === routerPath)?.completed;
      if (!isCompleted) {
        window.location.href = completedSection.path;
        return false;
      }
      return true;
    });
  }, [completedSection.hash, completedSection.path, completedSection.sections, router]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => setRouting(true));
    router.events.on('routeChangeComplete', () => setRouting(false));

    return () => {
      router.events.off('routeChangeStart', () => setRouting(false));
      router.events.off('routeChangeComplete', () => setRouting(false));
    };
  }, [router.events]);
  /**
   * On initialization, we want to ensure we redirect the user to their last completed step
   */
  useEffect(() => {
    if (application && !mountRef.current) {
      const { path, hash } = getLastCompletedSection(application);
      const navigationPath =
        application.loanStage !== LoanStage.AppCreated
          ? `/${ROUTES.CUSTOMER_PORTAL}/${application.id}/${CUSTOMER_PORTAL_ROUTES.DASHBOARD}`
          : path;

      router.push({
        pathname: navigationPath,
        hash: hash || undefined,
      });
      mountRef.current = true;
    }
  }, [application, router]);

  return <>{!isRouting && children}</>;
};

export default NavigateFlow;
