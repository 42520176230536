import { NAMESPACE } from '@models/enums/namespace.enum';
import { useTranslation } from 'next-i18next';
import { fsra, mainAnchors, secondaryAnchors } from './anchor.config';
import StyledFooter from './Footer.styles';
import { usePartnerContext } from 'contexts/partner';

const Footer = () => {
  const { t } = useTranslation([NAMESPACE.DEFAULT, NAMESPACE.PROMOTION]);
  const { partner } = usePartnerContext();
  return (
    <StyledFooter.FooterWrapper>
      <StyledFooter.AnchorWrapper>
        {mainAnchors
          .filter((anchor) => (anchor?.condition ? anchor.condition({ partner }) : true))
          .map((anchor) => (
            <StyledFooter.Anchor
              key={`anchor-${anchor.id}`}
              data-testid={`anchor-${anchor.id}`}
              href={t(anchor.url, { ns: anchor.ns })}
              target="_blank"
            >
              {t(anchor.label, { ns: anchor.ns })}
            </StyledFooter.Anchor>
          ))}
      </StyledFooter.AnchorWrapper>
      <StyledFooter.TermsConditionsWrapper>
        {secondaryAnchors.map((anchor) => (
          <StyledFooter.SecondaryAnchor
            key={`anchor-${anchor.id}`}
            data-testid={`anchor-${anchor.id}`}
            href={t(anchor.url)}
            target="_blank"
          >
            {t(anchor.label)}
          </StyledFooter.SecondaryAnchor>
        ))}
        <StyledFooter.TextFsra key={`label-${fsra.id}`} data-testid={`label-${fsra.id}`}>
          {t(fsra.label)}
        </StyledFooter.TextFsra>
      </StyledFooter.TermsConditionsWrapper>
    </StyledFooter.FooterWrapper>
  );
};

export default Footer;
