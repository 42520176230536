import Link from 'next/link';
import styled from 'styled-components';

const MenuStyled = {
  Container: styled.nav`
    display: flex;
    flex-direction: column;
    min-height: 55vh;
    padding: 0 14px;
  `,
  MenuList: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  `,
  MenuItem: styled.li`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 24px 0;
    border-bottom: 1px solid ${({ theme }) => theme.border.disabled};

    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  `,
  MenuLink: styled(Link)`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 98%;
  `,
  MenuItemButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 98%;
  `,
  NestedTitleWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 25%;
  `,
  NestedTitle: styled.h2`
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  `,
  PreviousButton: styled.button`
    all: unset;
    height: 24px;
    width: 24px;
  `,
  NestedWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
};

export default MenuStyled;
