import { Button } from '@pinecorpca/spruce';
import styled from 'styled-components';

const StyledLogout = {
  LogoutButton: styled(Button)`
    width: 100px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      width: 100%;
    }
  `,
};

export default StyledLogout;
