import Loader from '@components/Loader/Loader';
import NavigateFlow from '@components/NavigateFlow/NavigateFlow';
import { IndicatorContextProvider, StepIndicator, useConstructSteps } from '@components/StepIndicator';
import { useMortgageAppQuery } from 'hooks';
import { useResponsive } from 'hooks/responsive';
import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';
import Footer from '../components/Footer/Footer';
import Styled from './LongFlowLayout.styled';

const DynamicHeader = dynamic(() => import('./components/Header'), { ssr: false });

interface FlowLayoutProps {
  children: React.ReactNode;
  disableNavigateFlow?: boolean;
}

const FlowLayout: FunctionComponent<FlowLayoutProps> = ({ children, disableNavigateFlow }: FlowLayoutProps) => {
  const { isDesktopOrLaptop } = useResponsive();
  const { data: application, isLoading } = useMortgageAppQuery();
  const steps = useConstructSteps(application);

  if (isLoading) return <Loader />;

  return (
    <IndicatorContextProvider steps={steps}>
      <DynamicHeader />
      <Styled.Wrapper>
        <Styled.Container>
          <Styled.Content>
            {!disableNavigateFlow ? <NavigateFlow application={application}>{children}</NavigateFlow> : children}
          </Styled.Content>
          {isDesktopOrLaptop && (
            <Styled.StepIndicatorWrapper>
              <StepIndicator />
            </Styled.StepIndicatorWrapper>
          )}
        </Styled.Container>
        <Footer />
      </Styled.Wrapper>
    </IndicatorContextProvider>
  );
};

export default FlowLayout;
