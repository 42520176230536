import Styled from './DesktopNav.styled';
import { useNavLinks } from '../../hooks/links.hooks';
import { Logout } from '@components/Layouts';
import MenuItem from './Menu/Menu';
import { useAuthentication } from '@/lib/auth';

const DesktopNav = () => {
  const links = useNavLinks();
  const { doLogout } = useAuthentication();

  return (
    <Styled.Nav data-testid="desktop-nav">
      <Styled.MenuList>
        {links.map((link) => (
          <MenuItem key={link.id} link={link} />
        ))}
      </Styled.MenuList>
      <Logout onClick={doLogout} />
    </Styled.Nav>
  );
};

export default DesktopNav;
