import Footer from '@components/Layouts/components/Footer/Footer';
import { useEntitiesQuery } from 'hooks';
import { FunctionComponent } from 'react';
import Styled from './Layout.styled';
import Header from './Header/Header';
import { Loader } from '@components/index';

interface LayoutProps {
  children: React.ReactNode;
  showFooter?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({ children, showFooter }: LayoutProps) => {
  const { isLoading } = useEntitiesQuery();

  if (isLoading) return <Loader timeout={400} />;

  return (
    <>
      <Header />
      <Styled.Container>{children}</Styled.Container>
      {showFooter && <Footer />}
    </>
  );
};

Layout.defaultProps = {
  showFooter: true,
};

export default Layout;
