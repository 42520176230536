import { FC } from 'react';
import Styled from '../Menu.styled';
import { HeaderLink } from '../../../../hooks/links.hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MenuListProps {
  links: HeaderLink[];
  onNext: (parentId: string) => void;
}

const MenuList: FC<MenuListProps> = ({ links, onNext }) => (
  <Styled.MenuList>
    {links.map((link) => (
      <Styled.MenuItem key={link.id} data-testid={`${link.id}-menu-item`}>
        {link.icon && <FontAwesomeIcon icon={link.icon} size="lg" />}
        {!!link.subLink ? (
          <Styled.MenuItemButton data-testid={`${link.id}-menu-button`} onClick={() => onNext(link.id)}>
            {link.label || ''}
          </Styled.MenuItemButton>
        ) : (
          <Styled.MenuLink data-testid={`${link.id}-menu-link`} href={link.href}>
            {link.label || ''}
          </Styled.MenuLink>
        )}
      </Styled.MenuItem>
    ))}
  </Styled.MenuList>
);

export default MenuList;
