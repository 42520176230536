import styled from 'styled-components';

const StyledLayout = {
  Container: styled.section`
    max-width: ${({ theme }) => theme.pineLayoutMaxWidth};
    min-height: calc(100vh - ${({ theme }) => theme.pineFooterHeight} - ${({ theme }) => theme.pineNavHeight});
    width: 100%;
    margin: ${({ theme }) => theme.pineNavHeight} auto 0;
    display: flex;
    flex-direction: column;
    padding: 40px 0 16px;

    /* prettier-ignore */
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 16px;
      overflow-y: auto;
      margin: ${({ theme }) => theme.pineNavHeightMobile} auto 0;
      min-height: calc(
        100vh - ${({ theme }) => theme.pineFooterMobileHeight} - ${({ theme }) => theme.pineNavHeightMobile});
    }
  `,
};

export default StyledLayout;
