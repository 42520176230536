import { useCallback, useState } from 'react';
import Styled from './Menu.styled';
import { useNavLinks } from '../../../hooks/links.hooks';
import Logout from '@components/Layouts/components/Logout/Logout';
import { useTransition, animated } from 'react-spring';
import MenuList from './components/MenuList';
import NestedList from './components/NestedList';
import { useAuthentication } from '@/lib/auth';
import { futuraPt, tiempos } from 'styles/fonts';

type LinkType = 'parent' | 'child';
type ItemType = {
  linkType: LinkType;
  parentId: string;
  direction: 'left' | 'right';
};

const Menu = () => {
  const links = useNavLinks();
  const { doLogout } = useAuthentication();
  const [itemType, setItemType] = useState<ItemType>({
    linkType: 'parent',
    parentId: links?.at(0)?.id || '',
    direction: 'left',
  });

  const transitions = useTransition(itemType, {
    keys: null,
    initial: { opacity: 1, transform: 'translate3d(0,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0, 0)' },
    from: { opacity: 0, transform: itemType.direction === 'left' ? 'translate3d(-100%,0, 0)' : 'translate3d(100%,0, 0)' },
    leave: { opacity: 0, transform: itemType.direction !== 'left' ? 'translate3d(-100%,0, 0)' : 'translate3d(100%,0, 0)' },
  });

  const renderList = useCallback(
    (linkType: ItemType) => {
      switch (linkType.linkType) {
        case 'parent':
          return (
            <MenuList
              links={links}
              onNext={(parentId) => setItemType((prev) => ({ ...prev, parentId, direction: 'right', linkType: 'child' }))}
            />
          );
        case 'child': {
          const { subLink } = { ...links?.find(({ id }) => id === linkType.parentId) };
          return !!subLink ? (
            <NestedList
              subLink={subLink}
              onPrevious={(parentId) => setItemType((prev) => ({ ...prev, parentId, direction: 'left', linkType: 'parent' }))}
            />
          ) : (
            <></>
          );
        }
        default:
          return null;
      }
    },
    [links]
  );

  return (
    <>
      {/* // injected styles since this is within a react portal  */}
      <Styled.Container className={`${tiempos.variable} ${futuraPt.variable}`}>
        {transitions((style, item) => (
          <animated.div style={{ position: 'absolute', top: 42, width: '90%', ...style }}>{renderList(item)}</animated.div>
        ))}
      </Styled.Container>
      <Logout onClick={doLogout} />
    </>
  );
};

export default Menu;
