import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { Modal } from '@pinecorpca/spruce';
import { useRouter } from 'next/router';
import Styled from './MobileNav.styled';
import Menu from './Menu/Menu';
import { useTranslation } from 'next-i18next';
import { NAMESPACE } from '@models/enums';

const MobileNav = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(NAMESPACE.COMMON);
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', () => setOpen(false));
    return () => {
      router.events.off('routeChangeStart', () => setOpen(false));
    };
  }, [router.events]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <>
      <Styled.BarsButton
        aria-label={t('NAVIGATION_MENU')}
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        data-testid="mobile-burger-menu-cta"
      >
        <FontAwesomeIcon icon={faBars} size="xl" />
      </Styled.BarsButton>
      <Modal
        data-testid="mobile-navigation"
        open={open}
        mobileView="sticky"
        onClose={() => setOpen(false)}
        closeOnEscape
        hasCloseButton={false}
        closeOnOutsideClick
      >
        <Styled.CloseButton aria-label={t('CLOSE', { ns: NAMESPACE.COMMON })} onClick={() => setOpen(false)}>
          <Styled.CloseButtonBar />
        </Styled.CloseButton>
        <Menu />
      </Modal>
    </>
  );
};

export default MobileNav;
