import { NAMESPACE } from '@models/enums';
import { LeadSource } from '@pinecorpca/evergreen';

type conditionType = {
  partner: LeadSource | null;
};

type Anchor = {
  id: string;
  label: string;
  url: string;
  ns?: NAMESPACE;
  condition?: (props: conditionType) => boolean;
};

export const mainAnchors: Anchor[] = [
  {
    id: 'home',
    label: 'HOME',
    url: 'HOME_URL',
  },
  {
    id: 'about',
    label: 'ABOUT',
    url: 'ABOUT_URL',
  },
  {
    id: 'learn',
    label: 'LEARN',
    url: 'LEARN_URL',
  },
  {
    id: 'contact',
    label: 'CONTACT',
    url: 'CONTACT_URL',
  },
  {
    id: 'wealthsimple',
    label: 'WEALTHSIMPLE_X_PINE',
    url: 'WEALTHSIMPLE_PARTNERSHIP_URL',
    ns: NAMESPACE.PROMOTION,
    condition: ({ partner }: conditionType) => partner === LeadSource.Wealthsimple,
  },
];

export const secondaryAnchors: Anchor[] = [
  {
    id: 'terms_conditions',
    label: 'TERMS_CONDITIONS',
    url: 'TERMS_CONDITIONS_URL',
  },
  {
    id: 'privacy_policy',
    label: 'PRIVACY_POLICY',
    url: 'PRIVACY_POLICY_URL',
  },
];

export const fsra = {
  id: 'FSRA',
  label: 'FSRA_PROVINCES',
};
