import Image from 'next/image';
import styled from 'styled-components';

const StyledLogo = {
  Image: styled(Image)`
    width: auto;
    height: 100%;
  `,
  Anchor: styled.a`
    width: auto;
    height: 30px;
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      width: auto;
      height: 20px;
    }
  `,
};

export default StyledLogo;
