import styled from 'styled-components';

const StyledLongFlowLayout = {
  Container: styled.section`
    position: relative;
    flex: 1;

    display: grid;
    gap: 48px;
    grid-template-columns: ${({ theme }) => theme.pineQuestionSectionMaxWidth} ${({ theme }) => theme.pineSideBarWidth};

    max-width: ${({ theme }) => theme.pineLayoutMaxWidth};
    margin: calc(${({ theme }) => theme.pineNavHeight}) auto 50px;
    padding-top: 40px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      margin: calc(${({ theme }) => theme.pineNavHeightMobile} + ${({ theme }) => theme.stepIndicatorMobileHeight}) auto 20px;
      grid-template-columns: 1fr;
      width: 100%;
      padding: 24px 20px;
    }
  `,
  Wrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 10px);
  `,
  StepIndicatorWrapper: styled.div`
    position: relative;
    left: 0;
    z-index: 1;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${({ theme }) => theme.pineQuestionSectionMaxWidth};
  `,
};

export default StyledLongFlowLayout;
