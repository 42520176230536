import { FC, useEffect, useRef, useState } from 'react';
import { HeaderLink } from '../../../hooks/links.hooks';
import Styled from './Menu.styled';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

interface MenuItemProps {
  link: HeaderLink;
}

const MenuItem: FC<MenuItemProps> = ({ link }) => {
  const [open, setOpen] = useState(false);
  const menuDropdownRef = useRef<HTMLUListElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', () => setOpen(false));
    return () => {
      router.events.off('routeChangeStart', () => setOpen(false));
    };
  }, [router.events]);

  useEffect(() => {
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false);
        menuButtonRef.current?.focus();
      }
    };

    const handleClickOutside = (e: MouseEvent) => {
      const refs = [menuDropdownRef, menuButtonRef];
      if (menuDropdownRef.current && !refs.some((ref) => ref?.current?.contains(e.target as Node))) {
        setOpen(false);
      }
    };

    document.addEventListener('keydown', closeOnEscape);
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('keydown', closeOnEscape);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Styled.MenuItem key={link.id} data-testid={`${link.id}-desktop-menu-item`}>
        {!!link?.subLink ? (
          <Styled.NavButton
            data-testid={`${link.id}-desktop-menu-item-button`}
            onClick={() => setOpen(!open)}
            aria-expanded={open}
            ref={menuButtonRef}
          >
            {link.label}
            <Styled.IconWrapper open={open}>
              <FontAwesomeIcon icon={faChevronDown} />
            </Styled.IconWrapper>
          </Styled.NavButton>
        ) : (
          <Styled.NavLink data-testid={`${link.id}-desktop-menu-item-link`} href={link.href}>
            {link.label}
          </Styled.NavLink>
        )}
      </Styled.MenuItem>
      {open && (
        <Styled.MenuDropdown ref={menuDropdownRef} data-testid={`${link.id}-desktop-menu-dropdown`}>
          {link?.subLink?.links?.map((subLink) => (
            <Styled.MenuDropdownItem key={subLink.id} data-testid={`${subLink.id}-sub-link-dropdown-item`}>
              <Styled.MenuDropdownLink href={subLink.href} data-testid={`${subLink.id}-sub-link-dropdown-link`}>
                {subLink.label}
              </Styled.MenuDropdownLink>
            </Styled.MenuDropdownItem>
          ))}
        </Styled.MenuDropdown>
      )}
    </>
  );
};

export default MenuItem;
