import styled from 'styled-components';

const StyledMobileNav = {
  BarsButton: styled.button`
    all: unset;
  `,
  CloseButton: styled.button`
    all: unset;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px 0;
  `,
  CloseButtonBar: styled.div`
    width: 56px;
    height: 4px;
    border-radius: 70px;
    background: rgba(0, 0, 0, 0.1);
  `,
};

export default StyledMobileNav;
