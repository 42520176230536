import Link from 'next/link';
import styled from 'styled-components';

const StyledMenu = {
  MenuItem: styled.li``,
  NavLink: styled(Link)`
    all: unset;
    color: ${({ theme }) => theme.text.secondary};
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.text.primary};
    }
  `,
  NavButton: styled.button`
    all: unset;
    display: flex;
    gap: 8px;

    color: ${({ theme }) => theme.text.secondary};
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.text.primary};
    }
  `,
  IconWrapper: styled.div<{ open: boolean }>`
    transform: rotate(${({ open }) => (open ? '-180deg' : 0)});
    transition: 0.2s ease-in-out;
  `,
  MenuDropdown: styled.ul`
    padding: 0;
    position: absolute;
    transform: translateX(0) translateY(70%);
    z-index: 999;
    list-style: none;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.stone.tint};
    background: ${({ theme }) => theme.background.primary};
    box-shadow: 0px 3px 8px 0px rgba(15, 18, 31, 0.24);
  `,
  MenuDropdownItem: styled.li`
    min-height: 40px;
    width: 100%;

    &:hover,
    &:focus-within {
      background-color: ${({ theme }) => theme.background.secondary};
    }
  `,
  MenuDropdownLink: styled(Link)`
    all: unset;
    display: block;
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    padding: 16px 12px;

    cursor: pointer;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.text.primary};
    }
  `,
};

export default StyledMenu;
