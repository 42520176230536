import styled from 'styled-components';

const StyledHeader = {
  Header: styled.header`
    position: fixed;
    top: 0;
    width: 100%;
    background: ${({ theme }) => theme.background.primary};
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05);
    padding: 18px 16px;
    z-index: 999;
  `,
  NavContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 auto;
    max-width: ${({ theme }) => theme.pineLayoutMaxWidth};

    @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
      display: grid;
      grid-template-columns: 300px 1fr;
      column-gap: 10%;
    }
  `,
};

export default StyledHeader;
