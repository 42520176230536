import { NAMESPACE } from '@models/enums';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import Styled from './Logout.styled';

interface LogoutProps {
  onClick: () => void;
}

const Logout: FC<LogoutProps> = ({ onClick }) => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);

  return (
    <Styled.LogoutButton size='small' onClick={onClick} color="secondary" data-testid="logout-cta">
      {t('LOGOUT_BUTTON')}
    </Styled.LogoutButton>
  );
};

export default Logout;
