import { FunctionComponent } from 'react';
import Styled from './ShortFlowLayout.styled';
import { ShortAppProvider } from 'contexts/short-app.context';
import dynamic from 'next/dynamic';
import { IndicatorContextProvider, StepIndicator } from '@components/StepIndicator';
import { useResponsive } from 'hooks/responsive';

const DynamicHeader = dynamic(() => import('./components/Header'), { ssr: false });
const DynamicFooter = dynamic(() => import('../components/Footer/Footer'), { ssr: false });
interface FlowLayoutProps {
  children: React.ReactNode;
}

const ShortFLowFlowLayout: FunctionComponent<FlowLayoutProps> = ({ children }: FlowLayoutProps) => {
  const { isDesktopOrLaptop } = useResponsive();

  return (
    <ShortAppProvider>
      <IndicatorContextProvider steps={[]}>
        <Styled.Wrapper>
          <DynamicHeader />
          <Styled.Section>
            <Styled.Content>{children}</Styled.Content>
            {isDesktopOrLaptop && (
              <Styled.StepIndicatorWrapper>
                <StepIndicator />
              </Styled.StepIndicatorWrapper>
            )}
          </Styled.Section>
          <DynamicFooter />
        </Styled.Wrapper>
      </IndicatorContextProvider>
    </ShortAppProvider>
  );
};

export default ShortFLowFlowLayout;
