import { FC } from 'react';
import Styled from '../Menu.styled';
import { SubLink } from '../../../../hooks/links.hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

interface NestedListProps {
  subLink: SubLink;
  onPrevious: (parentId: string) => void;
}

const NestedList: FC<NestedListProps> = ({ subLink, onPrevious }) => (
  <Styled.NestedWrapper>
    <Styled.NestedTitleWrapper>
      <Styled.PreviousButton data-testid={`${subLink.parentId}-previous-button`} onClick={() => onPrevious(subLink.parentId || '')}>
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </Styled.PreviousButton>
      <Styled.NestedTitle data-testid={`${subLink.parentId}-sub-links-title`}>{subLink?.title || ''}</Styled.NestedTitle>
    </Styled.NestedTitleWrapper>
    <Styled.MenuList>
      {subLink.links?.map((link) => (
        <Styled.MenuItem key={link.id} data-testid={`${link.id}-menu-item`}>
          <Styled.MenuLink href={link.href} data-testid={`${link.id}-menu-link`}>
            {link.label || ''}
            <FontAwesomeIcon icon={faChevronRight} size="lg" />
          </Styled.MenuLink>
        </Styled.MenuItem>
      ))}
    </Styled.MenuList>
  </Styled.NestedWrapper>
);

export default NestedList;
