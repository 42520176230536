import Logo from '@components/Layouts/components/Logo/Logo';
import Styled from './Header.styled';
import MobileNav from './compnents/MobileNav/MobileNav';
import { useResponsive } from 'hooks/responsive';
import DesktopNav from './compnents/DesktopNav/DesktopNav';

const Header = () => {
  const { isTabletOrMobile, isClient } = useResponsive();
  return (
    <>
      <Styled.Header>
        <Styled.NavContainer>
          <Logo />
          {isClient && <>{isTabletOrMobile ? <MobileNav /> : <DesktopNav />}</>}
        </Styled.NavContainer>
      </Styled.Header>
    </>
  );
};

export default Header;
